import React from 'react';
import _ from 'lodash';
import FeatureCategory from "./FeatureCategory"

const OptionContainer = ({config, onConfigChange}) => {
  const {features} = config;

  const setFeature = (feature) => (update) => {
    if (_.isFunction(update)) {
      update = update(feature);
    }

    onConfigChange((config) => {
      return {
        ...config,
        features: _.map(config.features, (cFeature) => {
          return cFeature === feature ? update : cFeature;
        })
      };
    })
  }
  return (
    <div>
      {_.map(features, (feature) => (
        <FeatureCategory
          key={feature.title}
          feature={feature}
          onFeatureChange={setFeature(feature)}/>
      ))}
    </div>
  );
};

export default OptionContainer;