import React from 'react';
import _ from 'lodash';
import SelectedTextGroup from './SelectedTextGroup';

const SelectedFeatureCategory = ({feature}) => {
  const {title, items} = feature;

  return (
      <div className="selected_feature_category">
        <h2>
          {title}
        </h2>

        {_.map(items, (item) => {
          return (
            <SelectedTextGroup 
              featureItem={item}
            />
          );
        })}
      </div>
  );
};

export default SelectedFeatureCategory;