import React from 'react';
import _ from 'lodash';
import SelectedFeatureCategory from './SelectedFeatureCategory';

const SelectedContainer = ({config}) => {
  const {features} = config;

  return (
    <div className="selected-option-container">
      <h1>Your custom skiff:</h1>
      <p>This is a summary of the skiff you are building.  Please note that any listed prices are informational estimates subject to change until you have executed a purchase agreement. </p>
      {_.map(features, (feature) => (
        <SelectedFeatureCategory
          key={feature.title}
          feature={feature}
        />
      ))}
    </div>
  );
};

export default SelectedContainer;