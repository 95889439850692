import React from 'react';
import _ from 'lodash';
import ImageRadioItem from './ImageRadioItem';

const FeatureImageRadioGroup = ({featureItem, onItemChange}) => {
  const selectItem = (value) => (_e) => {
    onItemChange((item) => {
      return {
        ...item,
        options: _.map(item.options, (iOption) => {
          return {...iOption, selected: (iOption.value === value)};
        })
      };
    });
  };

  return (
      <div className="feature_item">
        <h3>{featureItem.title}</h3>

        <div className="feature_radio_options">
          <ul>
          {featureItem.options.map((option, index) => (
            <ImageRadioItem
              value={option.value}
              fieldName={featureItem.name}
              descriptionText={option.description}
              checked={option.selected}
              title={option.title}
              imgUrl={option.image}
              includedInBase={option.includedInBase}
              price={option.price}
              onChange={selectItem(option.value)}
            />
          ))}
          </ul>
        </div>
      </div>
  );
};

export default FeatureImageRadioGroup;