import React from 'react';
import SelectedTextItem from './SelectedTextItem';

const SelectedTextGroup = ({featureItem}) => {

  return (
      <div className="selected_feature_item">

        <div className="selected_texts">
          <ul>
          {featureItem.options.map((option, index) => (
            option.selected && (
              <SelectedTextItem
                title={option.title}
                price={option.price}
              />
            )
          ))}
          </ul>
        </div>
      </div>
  );
};

export default SelectedTextGroup;