import React from 'react';

const RadioItem = ({ value, fieldName, checked, title, includedInBase, price, descriptionText, onChange }) => {
    return (
    <li className={(checked) ? "option selected" : "option"}>
      <input
        type="radio"
        name={fieldName}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="option_text">{title}</span>
      <span className="includedInBasePrice">{ (includedInBase) ? "yes" : "no" } </span>
      <span className="price_change_container">
        <span className="price_change_text">+ ${price.toLocaleString()}</span>
      </span>
      { descriptionText !== "" && (
        <span className="descriptionText">{descriptionText}</span>
      )} 
    </li>
  );
};

export default RadioItem;
