import React from 'react';

const ImageRadioItem = ({ value, fieldName, checked, title, includedInBase, price, descriptionText, imgUrl, onChange }) => {
    return (
    <li className={(checked) ? "option selected image" : "option image"}>
      <input
        className="image"
        type="radio"
        name={fieldName}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      
        <span className="option_text">
          <span class="image">
            <img src={imgUrl} alt="{title}"/>
          </span>
          <span class="description">
            {title}
          </span>
        </span>
        <span className="includedInBasePrice">{ (includedInBase) ? "yes" : "no" } </span>
        <span className="price_change_container">
          <span className="price_change_text">+ ${price.toLocaleString()}</span>
        </span>
        { descriptionText !== "" && (
          <span className="descriptionText">{descriptionText}</span>
        )}
    </li>
  );
};

export default ImageRadioItem;
