import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// var skiffConfig = false

const configURL = () => {
  const inflections = [
    'zeroDontUse',
    'oneDontUse',
    '18-mangrove',
    '21-mangrove',
    '18-islamorada',
    '21-islamorada',
    '18-LM',
    '21-LM',
    '18-R',
    '21-R',
    '18-challenger'
  ]

  const domain = (process.env.REACT_APP_BACKEND_DOMAIN) ? process.env.REACT_APP_BACKEND_DOMAIN : "https://build.chittumskiffs.com"

  var configuration = 4

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const param = urlParams.get("sbc")
  if (param) {
    configuration = inflections.indexOf(param)
    if (configuration === -1) {
      configuration = param
    }
  }
  return `${domain}/api/v1/base_configs/${configuration}.json`
}

const Configurator = () => {
  const [skiffConfig, setSkiffConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(configURL());
        const config = await response.json();
        config.builder.requests_quote = false
        setSkiffConfig(config);
      } catch (error) {
        console.error("Error fetching configuration:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (skiffConfig) {
      skiffConfig.features.forEach((g) => {
        g.items.forEach((feat) => {
          if (feat.image_layer !== null && feat.image_layer !== "") {
            let layerId = feat.image_layer;
            let selected_class = "fill ";
            feat.options.forEach((opt) => {
              if (opt.selected === true) {
                selected_class += opt.image_layer_option;
              }
            });
            let theDiv = document.getElementById(layerId);
            if (theDiv) {
              theDiv.className = selected_class;
            }
          }
        });
      });
    }
  }, [skiffConfig]);


  if (!skiffConfig) {
    return <div>Loading...</div>;
  }

  return <App initialConfig={skiffConfig} />;
};

const root = ReactDOM.createRoot(document.getElementById('configurator'));
root.render(
  <React.StrictMode>
    <Configurator />
  </React.StrictMode>
);

// reportWebVitals();
