import React from 'react';
import CheckboxItem from './CheckboxItem'
import _ from 'lodash';

const FeatureCheckboxGroup = ({featureItem, onItemChange}) => {
  const toggleSelected = ({selected, ...option}) => ({...option, selected: !selected})
  const toggleItem = (value) => (_e) => {
    onItemChange((item) => {
      return {
        ...item,
        options: _.map(item.options, (iOption) => {
          return iOption.value === value ? toggleSelected(iOption) : iOption;
        })
      };
    });
  }

  return (
    <div className="feature_item">
      <h3>{featureItem.title}</h3>

      <div className="feature_checkbox_options">
        <ul>
        {featureItem.options.map((option, index) => (
            <CheckboxItem
              value={option.value}
              fieldName={featureItem.title}
              checked={option.selected}
              title={option.title}
              descriptionText={option.description}
              includedInBase={option.includedInBase}
              price={option.price}
              onChange={toggleItem(option.value)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FeatureCheckboxGroup;