import React from 'react';

const SelectedTextItem = ({ title, price }) => {
    return (
    <li className="selected_text">
      <span className="option_text">{title}</span>
      <span className="price_container">
        <span className="price_text">+ ${price.toLocaleString()}</span>
      </span>
    </li>
  );
};

export default SelectedTextItem;
